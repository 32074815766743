import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { ControllersService } from "src/app/services/controllers.service";
import { ResolverService } from "src/app/services/resolver.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-initiated-payout",
  templateUrl: "./initiated-payout.component.html",
  styleUrls: ["./initiated-payout.component.scss"],
})
export class InitiatedPayoutComponent implements OnInit {
  brands: Array<any> = [];
  category: Partial<any> = {};
  dtTrigger: Subject<any> = new Subject<any>();
  segment: number = 5;
  results: Array<any> = [];
  bundles: Array<any> = [];
  dtOptions: DataTables.Settings = {}; // Settings for DataTables
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  start: number = 0; // Pagination start
  length: number = 50; // Default page size
  searchStr: string = ''; // Search term for filtering
  totalLength: number=0;
  allPData: any[];
  constructor(
    private router: Router,
    public resolver: ResolverService,
    public controller: ControllersService,
    public location: Location
  ) {}

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      serverSide: true, // Enable server-side processing
      pageLength: 50,
      dom: 'frtlp',
      responsive: true,
      language: {
        search: 'Search :',
        searchPlaceholder: 'query',
        paginate: {
          next: '&#8594;',   // or '→'
          previous: '&#8592;', // or '←'
          first: '<<',        // First page button
          last: '>>',         // Last page button
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.start = dataTablesParameters.start;
        this.length = dataTablesParameters.length;
        this.searchStr = dataTablesParameters.search.value;
    
        // Call the server-side pagination API
        this.results = [];
        this.getPaginatedPayouts(this.searchStr,this.start , this.length ).subscribe((response: any) => {
          console.log('hiii');
          
          this.results = response.data; // Update product data
          this.allPData = response.data;
          // this.totalLength += response.data.length; 
          // // this.totalRecords = response.length + 50; // Set total records
          // if (response.length > 0) {
          //   this.totalLength += 50;
          // }
            console.log(this.totalLength);
          callback({
            recordsTotal: response?.total, // Total records in the database
            recordsFiltered: response?.total, // Total records after filtering
            data: [], // Data to display
          });
        });
      },
    };
  }

  // public getAllPayoutss() {
  //   // this.results = [];
  //   this.controller.presentLoading("Getting Payouts list...");
  //   this.resolver.getAllPayouts().subscribe((data: any[]) => {
  //     // this.controller.loadCtrl.dismiss();
  //     this.results = data;
  //     this.allPData = data;
  //     this.dtTrigger.next();
  //   });
  // }

  getPaginatedPayouts(searchStr: string,start: number, length: number) {
    return this.resolver.getAllPayouts(searchStr,start, length );
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      if (dtInstance) dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  public onSegmentSelection(event?) {
    this.results = [];
    if (this.segment == 1) {
      this.results = [];
      this.results = this.allPData.filter((item) => item.status == 1);
    }
    if (this.segment == 2) {
      this.results = [];
      this.results = this.allPData.filter((item) => item.status == 2);
    }
    if (this.segment == 3) {
      this.results = [];
      this.results = this.allPData.filter((item) => item.status == 3);
    }
    if (this.segment == 4) {
      this.results = [];
      this.results = this.allPData.filter((item) => item.status == 4);
    }
    if (this.segment == 5) {
      this.results = [];
      this.results = this.allPData;
    }
    // this.rerender();
  }

  goToInitiated(data) {
    this.router.navigate([`/sg-admin/initiated-payout/details/${data.id}`]);
  }

  goToBack() {
    this.location.back();
  }

  convertUnixToDate(unixTimestamp: number): Date {
    return new Date(unixTimestamp * 1000);
  }

   // Destroy DataTable when component is destroyed
   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
