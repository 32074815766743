import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ControllersService } from 'src/app/services/controllers.service';
import { ResolverService } from 'src/app/services/resolver.service';
import { InitiatedPayoutEditComponent } from './initiated-payout-edit/initiated-payout-edit.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-initiated-payout-details',
  templateUrl: './initiated-payout-details.component.html',
  styleUrls: ['./initiated-payout-details.component.scss'],
})
export class InitiatedPayoutDetailsComponent implements OnInit {

  promoCodeData: Array<any> = [];

  payoutData: Array<any> = [];
  payoutId: any;
  orderid: any[];
  paymentmode: any[];
  constructor(
    public resolver: ResolverService,
    public controller: ControllersService,
    public router: Router,
    private activatedRoute:ActivatedRoute,
    public location: Location,
  ) { 

    this.payoutId = this.activatedRoute.snapshot.paramMap.get('id');
  }
  ionViewDidEnter(): void {
    this.getSellerData();
  }

  ngOnInit() {
  }

  public getSellerData() {
    this.controller.presentLoading("Getting Pay-Out Details...");
    this.resolver.getPayoutsById(this.payoutId).subscribe((data: any) => {
      this.payoutData.push(data) ;
      // this.payoutData = data.map(item => ({ ...item, isSelected: false }));
      // if (this.sellerData.length) {
      //   this.rerender();
      // } else {
      //   this.dtTrigger.next();
      // }
      this.getSellerOrder()
    });
  }

getSellerOrder(){
  console.log(this.payoutData);
  
  this.payoutData[0].orderId.map((x)=>{
    console.log(x);
    
    this.resolver.newGetOrderById(x).subscribe((data: any) => {
      console.log(data);
       });
  })
 
}
  goToBack() {
    this.location.back();
  }

  async openCrudModal(type) {
    const modal = await this.controller.modalCtrl.create({
      component: InitiatedPayoutEditComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        type:type,
        payoutsData:this.payoutData
      }
    });
    modal.onDidDismiss().then((data) => {
    })
    return await modal.present();
  }

  getStatusText(status: number): string {
    switch (status) {
      case 1:
        return 'Initiated';
      case 2:
        return 'Partial';
      case 3:
        return 'Success';
      case 4:
        return 'Failed';
      default:
        return '';
    }
  }
  
}
