import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaxesRoutingModule } from './taxes-routing.module';
import { HeaderModule } from 'src/app/components/header/header.module';
import { SideMenuModule } from 'src/app/components/side-menu/side-menu.module';
import { FooterModule } from 'src/app/components/footer/footer.module';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TaxesComponent } from './taxes.component';

  @NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      HeaderModule,
      SideMenuModule,
      DataTablesModule,
      TaxesRoutingModule
    ],
    declarations: [TaxesComponent]
  })
export class TaxesModule { }
