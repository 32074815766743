import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ControllersService } from 'src/app/services/controllers.service';
import { ResolverService } from 'src/app/services/resolver.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss'],
})
export class TaxesComponent implements OnInit {
  @Input() TaxesInfo: any;
  posts: Array<any> = [];
  @Input() item = this.posts;
  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    processing: true,
    pageLength: 50,
    dom: 'frtlp',
    responsive: true,

    columnDefs: [
      { orderable: true, targets: 0 }
    ],
    order: [
      [0, 'desc'],

    ],
    language: {
      search: "Search :",
      searchPlaceholder: "query",
      paginate: {
        next: '&#8594;', // or '→'
        previous: '&#8592;', // or '←' 
        first: '',
        last: ''
      },

    }

  };
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  taxName: any;
  taxComputaion: any;
  taxAmount: any;
  constructor(public resolver: ResolverService, public controller: ControllersService, private router: Router, private auth: AuthService) {
  }

  ngOnInit() {
    this.getposts();
  }
  public getposts() {
    this.controller.presentLoading("Getting posts...");
    this.resolver.getTaxes().subscribe((data: any) => {
      this.posts = data;
      this.dtTrigger.next();
    })
  }

  public onStatusChange(id, event) {
  }

  AddNewTax() {
    this.router.navigate(['/sg-admin/tax/addNewTaxes']);
  }
  UpdateData(data) {
    this.auth.myMethod(data);
    this.router.navigate(['/sg-admin/tax/addNewTaxes'])
    localStorage.setItem('taxData', JSON.stringify(data));
  }

}
