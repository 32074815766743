import { Component, OnInit, Input } from "@angular/core";
import { ControllersService } from "src/app/services/controllers.service";
import { ResolverService } from "src/app/services/resolver.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-initiated-payout-edit",
  templateUrl: "./initiated-payout-edit.component.html",
  styleUrls: ["./initiated-payout-edit.component.scss"],
})
export class InitiatedPayoutEditComponent implements OnInit {
  @Input() type: any;
  @Input() payoutsData: any;
  amount: number = 0;
  paymentMode: string = "";
  reason: string = "";
  paymentType: any;
  errorMessage: string;
  isFormValid: boolean = true;

  constructor(
    public resolver: ResolverService,
    public controller: ControllersService,
    private http: HttpClient,
    public router: Router,
  ) {}

  ngOnInit() {}

  updatePayout() {
    if (this.type == "Proces") {
      if (this.amount > this.payoutsData[0].totalAmount) {
        this.errorMessage = "Amount should be equal to the total amount.";
      } else if (this.amount === this.payoutsData[0].totalAmount) {
        this.errorMessage = "";
        this.paymentMode = "Final";
      } else {
        this.errorMessage = "";
        this.paymentMode = "Partial";
      }
      if (this.amount && this.reason && this.paymentType) {
        this.isFormValid = true;
        const payload = {
          totalAmount: this.amount,
          // paymentMode: this.paymentMode,
          comment: this.reason,
          paymentmode: this.paymentType,
        };
        this.paymentMode == "Final"
          ? this.resolver
              .PayoutSuccess(this.payoutsData[0].id, payload)
              .subscribe((data: any) => {
                this.controller.presentLoading("Pay Successfully");
                this.controller.modalCtrl.dismiss();
                this.router.navigate([`/sg-admin/initiated-payout/`])
              })
          : this.resolver
              .PayoutPartial(this.payoutsData[0].id, payload)
              .subscribe((data: any) => {
                this.controller.presentLoading("Pay Partial")
                this.controller.modalCtrl.dismiss();
                this.router.navigate([`/sg-admin/initiated-payout/`])
              });
      } else {
        this.isFormValid = false;
      }
    } else {
      const payload = {
        comment: this.reason,
      };
      this.resolver
        .Payoutfail(this.payoutsData[0].id, payload)
        .subscribe((data: any) => {
          this.controller.presentLoading("Cencel Successfully")
          this.controller.modalCtrl.dismiss();
          this.router.navigate([`/sg-admin/initiated-payout/`])
        });
    }
  }

  dismiss() {
    this.controller.modalCtrl.dismiss();
  }
}
