import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitiatedPayoutComponent } from './initiated-payout.component';
import { InitiatedPayoutDetailsComponent } from './initiated-payout-details/initiated-payout-details.component';

const routes: Routes = [
  {
    path:'',
    component:InitiatedPayoutComponent
  },
  {
    path:'details',
    component:InitiatedPayoutDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InitiatedPayoutRoutingModule { }
