import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InitiatedPayoutRoutingModule } from './initiated-payout-routing.module';
import { InitiatedPayoutComponent } from './initiated-payout.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from 'src/app/components/header/header.module';
import { SideMenuModule } from 'src/app/components/side-menu/side-menu.module';
import { IonicSelectableModule } from 'ionic-selectable';


@NgModule({
  declarations: [
    InitiatedPayoutComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    HeaderModule,
    SideMenuModule,
    IonicSelectableModule,
    InitiatedPayoutRoutingModule
  ]
})
export class InitiatedPayoutModule { }
