import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TaxesComponent} from './taxes.component';
import {AddNewTaxesComponent} from './add-new-taxes/add-new-taxes.component'

const routes: Routes = [
  {
    path: 'sg-admin/tax',
    component:TaxesComponent,
  },
  {
    path: 'addNewTaxes',
    loadChildren: () => import('./add-new-taxes/add-new-taxes.module').then( m => m.AddNewTaxesModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class TaxesRoutingModule { }
